

import store from '@/store/index'
let IsXiTai = store.getters.IsXiTai
export default {
	route: {
		//菜单名
		valentinesDayActive:'情人节活动',
		cooperationPlan:'合作计划',
		myExpertLivedetails:'达人信息配置',
		myExpertLive:'我的达人',
		expertLiveOpenSeadetails:'达人公海详情',
		expertLiveOpenSea:'达人公海',
		expertLive:'达播',
		TagLibraryList:'标签库',
		XTTagLibrary:'标签库',
		CustomerRecycleBin:'客户回收站',
		SellCustomerEdit:'客户信息配置',
		CustomerSource:'客户来源',
		CustomerType:'客户类型',
		SellCustomerDetail:'客户详情',
		CustomerList:'客户管理',
		sellCustomerManagePage:'客户管理',
		sellManage:'销售管理',
		taokeConfig:'收益配置',
		taokeEarnings:'收益明细',
		taokeOrder:'淘客订单',
		taokeShop:'淘客商品',
		taokeDistribution:'淘客分销',
		videoGoodsDetails:'商品配置',
		videoAfterSaleDetails:'视频号售后',
		videoOrderDetails:'视频号订单',
		videoShop:'视频号小店',
		videoGoodsList:'商品列表',
		videoOrderList:'视频号订单',
		videoAfterSale:'视频号售后',
		videoSharer:'分享员管理',
		videoLiveBinding:'视频号直播',
		videoShopBinding:'小店绑定',
		helpPro:'助力Pro版',
		helpProdetails:'配置助力Pro版',
		AITongueExamination:'AI舌诊',	
		jushuitan:'聚水潭',
		logisticscompany:'物流公司映射',
		shopattribute:'商品属性',
		takeTestRecord:'测一测记录',
		takeTestEdit:'配置测一测',
		takeTest:'测一测',	
		pointsCodeedit:'配置积分码',		
		pointsCodedata:'兑换码明细',
		pointsCode:'积分码',		
		wangdiantong:'旺店通',
		merchandising:'商品销售排行',
		customercontribution:'客户贡献排行',
		wanliniu:'万里牛',
		goodsdetails:'商品详情页',
		agentdriverrecruiting:'推手招募',
		driverrecruiting:'推手招募',
		Interfacelicensing:'接口许可管理',
		shortvideoindex:'短视频带货',
		shortvideodetail:'配置短视频',
		routuanOrder: '肉团订单',
		promote:'直播推广',
		sharememberdetail:'分享员带货数据',
		sharememberexportlist:'导入记录',
		sharemember:'直播间分享员',
		blacklist:'黑名单管理',
		businessdata:'经营数据',
		profitdetail:'毛利项目明细',
		imgWatermarkdetail:'配置主图水印',
		imgWatermarklist:'主图水印',
		rosepowderindex:"涨粉营销",
		rosepowderdetail:"配置涨粉营销",
		rosepowderdata:"渠道涨粉数据",
		ConfigurationSmart:"配置智能回复",
		authorization:'客服帐号',

		smsaccount:'短信账户',
		smsaccountindex:'短信账户',
		agentReceiptAndPaymentRecord: '收支记录',
		receiptAndPaymentRecord: '收支记录',
		agentDistribution: '推手分销',
		inquiryDistribution: '推手分销',
		material: '素材管理',
		friendWelcomeMessage: '好友欢迎语',
		friendWelcomeMessageEdit: '编辑好友欢迎语',
		joinGroupMessage: '进群欢迎语',
		joinGroupMessageEdit: '编辑进群欢迎语',
		createApp: '创建自建应用',
		autoAddTagEdit: '编辑自动打标签',
		productRecommend: '商品推荐',
		customerOrder: '客户订单',
		customerPortrayal: '客户画像',
		chatSidebar: '聊天侧边栏',
		qyWechat: '企业微信',
		flow: '引流获客',
		autoAddTag: '自动打标签',
		dataAnalysis: '数据分析',
		door: '线下门店',
		customerGroupManage: '客户群管理',
		customManage: '企微客户',
		deleteEmployeeManage: '删人提醒',
		lossManage: '流失提醒',
		shopCenter: '商城管理',
		PayrecharRecord: '货款充值记录',
		Specagentconfige: '指定代理配置',
		singleRate: '面单流量统计',
		refundRecord1: '退款记录',
		frightTool: '包邮工具',
		addFeighttool: '添加包邮工具',
		orderMange: '订单管理',
		orderDetailpage: '订单详情',
		lookFeighttool: '查看包邮工具',
		mallPage: '装修',
		mallPagemange: '商城装修',
		mallPageDetails: '页面装修',
		homeTitle: '实时概览',
		setMenupage: '自定义菜单',
		customerServe: '客服管理',
		greetings: '问候语',
		quickReply: '快捷回复',
		selfMenu: '自助菜单',
		salesPerformance: '销售业绩',
		workload: '工作量',
		flowAnalysisChild: '流量分析',
		dataStatisticPage: '流量分析',
		productAnalysisPage: '商品分析',
		businessAnalysisPage: '交易分析',
		customAnalysisPage: '客户分析',
		marketAnalysisPage: '营销分析',
		marketingOverview: '营销概览',
		broadCast: "小程序直播",
		broadcastManage: '直播间管理',
		storePerformance: '门店业绩',
		settleDetails: '结算业绩详情',
		payDetails: '支付业绩详情',
		employeePerformance: '店员分销',
		agentemployeePerformance: '店员分销',
		lockDetailpage: '锁粉明细',
		agentlockDetailpage: '锁粉明细',
		sellDetailpage: '销售明细',
		agentsellDetailpage: '销售明细',
		drawDetailpage: '提成明细',
		agentdrawDetailpage: '提成明细',
		employeePerformanceIndex: '店员业绩',
		agentemployeePerformanceIndex: '店员业绩',
		writeOffRecords: '核销记录',
		exportsSetting: '接口配置',
		goodsExport: '商品导入',
		myNeedTitle: '我的需求',

		supplyGoods: '我的供货',
		supplyMarket: '市场选货',
		supplyDetail: '商品详情',
		supplyShop: '供货商店铺',


		batchChangePrice:"批量改商品",

		shipAddress: '发货地址',
		bindingLogistics: '绑定物流',
		printerManagement: '打印员管理',
		batchOrders: '批量打单',
		typingRecord: '打单记录',
		bathOrdersPrant: '批量打单',
		lookGiftActive: '查看支付有礼',

		MessageSends: '短信群发',
		ZeroPermit: '0元授权',
		Promotionaltools: '推广工具',
		creatGoodDetails: '发布商品',
		// shopDetail:'订单详情',
		// agentModel: '代理模式配置',
		rewardmodel: '奖励模式配置',
		ActivityMange: '活动管理',
		TeamredActivity: '群红包活动',
		RedpayRecord: '红包发放记录',
		EditActivitymange: '活动编辑',
		groupMange: '团购管理',
		groupMangeDetails: ' 团购编辑',
		companyOrders: '公司订单',
		ordersDetail: '订单详情',
		withinGroup: '群内订单',
		grouporderMange: '团单管理',
		groupIrderDetails: '团单详情',
		innerorderDetails: '订单详情',
		angecMange: '代理管理',
		angecAddd: '代理添加',
		allianceMange: '联盟长管理',
		// customerMange: '客户管理',
		customerMange: '锁粉管理',
		NewcustomMange: '客户管理',
		angecChart: '代理结构图',
		recommentChart: '推荐结构图',
		drainagePost: '引流海报',
		carPost: '营销海报',
		goods: '商品管理',
		goodsManage: '商品编辑',
		goodsName: '商品',
		modelName: '级别配置',
		userName: '用户管理',
		gropuName: '团购管理',
		setName: '设置',
		dimensionCode: '二维码管理',
		ColleCodSet: '收款码设置',
		orderName: '订单管理',
		finceName: '财务管理',
		AgRechaManage: '代理充值管理',
		home: '首页',
		otherSet: '其他配置',
		giftMange: '礼包管理',
		giftDetails: '礼包信息编辑',
		newAngecModel: '代理模式配置',
		batchEditMetail: '编辑素材',
		serviceFee: '技术服务费',
		AgentsetMange: '代理资产管理',
		AgentMoneyRecord: '代理收益记录',
		AgentWithdrawRecord: '代理提现记录',
		AgentLineMoney: '代理线下收益',
		allOrder: '历史订单',
		Peorder: '待审单',
		sendGood: '已审单',
		shoppOrder: '全部订单',
		WithdrawalAudit: '提现审核',
		otherSetmange: '其他设置',
		WithruleSet: '提现规则设置',
		madeGroupmange: '团购管理(万嘉汇)',
		madegroupDetail: '团购详情(万嘉汇)',
		teamStatistics: '团队回款统计',
		userDataStatistics: '用户数据统计',
		teamPriceStatistics: '团品价格分布',
		tagUserStatistics: '用户层级分布',
		datasStatistics: '数据指标解释',
		specialMange: '专题管理',
		specialDetails: '专题编辑',
		refundRecord: '退款记录',
		refundRecordDetail: '退款单详情',
		columnConfiguration: '栏目配置',
		personalMall: '个人中心',
		barrageAd: '弹屏广告',
		mallFooterConfig: '底部导航',
		paySuccess: '支付成功页',
		settingPay: '支付设置',
		submitInformation: '接入Adapay支付',
		replenishInformation: '接入Adapay支付',
		submitInformationDetail: '接入Adapay支付',
		openPayby: '接入Adapay支付',

		weChatService: '微信服务',
		custoMenu: '自定义菜单',

		CarouseMange: '轮播图管理',
		carouserIndex: '轮播图管理',
		addCarouse: '轮播图编辑',

		shippingTemplates: '运费模板',
		addTemplate: '编辑运费模板',

		supplyOrderpage: '供应链团单',
		supplygroupDetails: '供应链团单详情',
		supplyOrderMange: '供应链订单',
		supplyOrderDetails: '供应链订单详情',
		supplyOrderSubmite: '供应链订单提交',

		accountBalance: '余额管理',
		accountBalanceCheck: '余额充值审核',
		Balancepay: '货款管理',


		openingAward: '需发开单奖',

		// supplierMange:'供应商团购',
		supplierDetails: '供货商品详情',
		materialDetail: '查看素材',

		angeceStatistics: '代理业绩统计',
		leaderStatistics: '联创代理业绩统计',
		rewardDetais: '奖励明细',
		needReward: '需发推荐奖',

		orderDetails: '订单详情', //新增
		metailLists: '素材管理',
		editMetails: '素材编辑',

		statisticsMange: '统计管理',

		peopleMange: '人员管理',
		departmentMange: '部门管理',
		positionMange: '职位管理',
		staffMange: '员工管理',
		addComment: '修改评论',
		commentMange: '评论管理',
		conmmentCheck: '评论审核',

		noticeMange: '供货信息管理',

		programStyle: '小程序风格',
		backgroundSet: '背景图配置',
		footSet: '底部工具栏',
		programDecoration: '小程序装修',

		checkConfiguration: '群签到配置',
		checkMange: '群签到管理',
		actionCenter: '互动中心',



		giftsManage: '赠品管理',
		purchase: '申请采购',
		purchaseManage: '门店采购管理',
		checkPurchase: '审核采购单',
		deliverPurchase: '发货采购单',
		recordPurchase: '采购记录',
		returnsManage: '门店退货管理',
		checkReturns: '审核退货单',
		storageReturns: '入库退货单',
		recordReturns: '退货记录',
		ordersManage: '订单管理',
		wmallOrders: '全部订单',
		aftersaleOrders: '售后单',
		stockManage: '库存管理',
		stockLogs: '库存日志',
		storeInfo: '门店信息',

		Set: '设置',
		address: '退货地址',
		purchaseDetail: '采购单详情',
		purchaseEdit: '编辑采购单',
		ReturnsDetail: '退货单详情',
		wmallOrderDetail: '订单详情',
		profitLoss: '损益单管理',
		profitLossDetail: '损益单详情',
		aftersaleDetail: '售后订单详情',
		dashboard: '首页',
		introduction: '简述',
		documentation: '文档',
		permission: '权限测试页',
		icons: '图标',
		components: '组件',
		componentIndex: '介绍',
		tinymce: '富文本编辑器',
		markdown: 'Markdown',
		jsonEditor: 'JSON编辑器',
		dndList: '列表拖拽',
		splitPane: 'Splitpane',
		avatarUpload: '头像上传',
		dropzone: 'Dropzone',
		sticky: 'Sticky',
		countTo: 'CountTo',
		componentMixin: '小组件',
		backToTop: '返回顶部',
		charts: '图表',
		keyboardChart: '键盘图表',
		lineChart: '折线图',
		mixChart: '混合图表',
		example: '综合实例',
		Table: 'Table',
		dynamicTable: '动态Table',
		dragTable: '拖拽Table',
		inlineEditTable: 'Table内编辑',
		complexTable: '综合Table',
		treeTable: '树形表格',
		customTreeTable: '自定义树表',
		tab: 'Tab',
		form: '表单',
		createForm: '创建表单',
		editForm: '编辑表单',
		errorPages: '错误页面',
		page401: '401',
		page404: '404',
		errorLog: '错误日志',
		excel: 'Excel',
		exportExcel: 'Export Excel',
		selectExcel: 'Export Selected',
		uploadExcel: 'Upload Excel',
		zip: 'Zip',
		exportZip: 'Export Zip',
		theme: '换肤',
		clipboardDemo: 'Clipboard',
		i18n: '国际化',
		Recommeshipstatistics: '推荐出货统计',
		BlackNameMange: '黑名单管理',
		goodsList: '商品列表',
		CustomerSearch: '客户查询',
		Customer: '客户运营',
		goodGroup: '商品分组',
		goodsBrand: '商品品牌',
		limitedDiscount: '限时折扣',
		growthValue: '成长值配置',
		pointsManager: '积分规则',
		addDiscount: '添加限时折扣',
		customerTag: '客户标签',
		pointsRulesEdit: '添加积分规则',
		doorManagerMenu: '门店管理',
		doorManager: '门店管理',
		doorTags: '门店标签',
		doorBill: '门店对账',
		doorGoodsEdit: '管理门店商品',
		doorSetting: '按商品管理门店',
		distribution: '分销中心',
		achievementStatistics: '推手业绩',
		achievementDetail: '店员业绩',
		commissionStatistics: '推手收益',
		commissionDetail: '提成概况',
		agentcommissionDetail: '提成概况',
		modeConfig: '级别配置',
		referrer: '推手业绩',
		promotionEffect: '商品推广效果',
		salesDetail: '销售明细',
		salesOverview: '销售概况',
		withDrawal: '分销提现',
		agentModwithDrawal: '分销提现',
		calendarCheckIn: '日历签到',
		issueCoupon: '发券宝',
		integralMall: '积分商城',
		market: '商城营销',
		customerFission: '客户裂变',
		agentFission: '推手裂变',
		basemarket: '基础营销',
		newUserFission: '拉新裂变',
		someTools: '配套工具',
		saleChannel: '销售渠道',
		wechatAccount: '微信公众号',

		// questionnaire:"问卷调查",
		questionlist:'问卷调查',
		questionresult:'问卷结果',
		editQuestionnaire: '问卷编辑',


		coupon: '优惠券',
		lottery: '转盘抽奖',
		editLotteryDetail: '编辑转盘抽奖',
		payGifts: '支付有礼',
		editPayGiftsDetail: '编辑支付有礼',
		channel: '渠道推广码',
		channelStatistics: '渠道数据统计',
		discountActivity: '满减送',
		packPrice: '打包一口价',
		orderCashBack: '订单立返',
		inquiry: '推手查询',
		setting: '设置中心',
		editSetting: '商城设置',
		roleManage: '角色管理',
		order: '订单',
		refundAfterSale: '退款售后',
		refundDetails: '售后详情',
		supplyRefund: '供货退款单',
		supplyRefundDetails: '售后详情',
		invitationPoster: '邀请海报',
		agentinvitationPoster: '邀请海报',
		vipLevel: '会员等级',
		vipEdit: '编辑VIP',
		CustomerDetail: '客户详情',
		editDiscountTicketDetail: '编辑优惠券',
		editCashDetaile: '编辑订单立返',
		editOverMoney: '编辑满减送活动',
		AddStore: '配置门店信息',
		refundDetails: '售后详情',
		editIntegralExchange: '编辑积分活动',
		dataStatistic: '数据统计',
		data: '数据',
		editIssueCoupon: '发券宝编辑',
		editRoleManage: '添加编辑角色',
    payApplyResult: '支付申请结果',
    setOfficialAccount: '公众号绑定',
    setPayApply: '申请第三方支付',
		wxAppManage: '微信小程序',
		editPriceDetaile: '编辑打包一口价活动',
		mallNavconfig: '主页导航',
		comment: '评价管理',
		remindArrival: '入库提醒',
		flowAnalysis: '流量分析',
		PageAnalysis: '页面分析',
		visitorAnalysis: '访客分析',
		productAnalysis: '商详页分析',
		productDealAnaly: '商品交易分析',
		SingelAnalydetail: '单品分析',
		businessAnalysis: '交易分析',
		wxFansAnalysis: '微信粉丝分析',
		marketAnalysis: '营销分析',
		Customtransanaly: '客户交易分析',
		customerOverview: '客户概览',
		assets: '财务中心',
		myAccount: '商城账户',
		suplatAccount: '供货平台账户',
		grossMargin: '订单毛利预算',
		purchaseSettle: '分销采购单结算',
		suplierSettle: '分销供货单结算',
		distributeDetail: '分销供货单详情',
		distributePage: '分销供货单',
		myNeed: '我的需求',
		addmyNeed: '添加需求',
		needDetail: '需求详情',
		MeterialManagelist: '商品素材管理',
		AddMeterialist: '添加素材',

		association: '社群营销',
		associateShare: '社群专享',
		associateConfig: '社群配置',
		associateData: '社群数据',
		EditassociateData: '添加社群专享',
		lookassociate: '查看社群专享',

		SystemNews: '系统消息',
		SystemNewsTitle: '系统消息',
		serviceSet: '客服设置',
		shopServe: '网页客服',
		wechatServe: '小程序客服',

		sellGroup: '多人拼团',
		editsellGroupDetail: '编辑多人拼团',
		looksellGroupActive: '查看多人拼团',
		Pushtradeanalysis: '推手流量数据',

		agent: '分销中心',
		agentConfig: '级别配置',
		agentmanagelist: '推手管理',
		benefit: '推手收益',
		benefitList: '推手收益',
		benefitDetail: '收益明细',
		rewardRules: '出货奖励规则',
		sellReward: '推手出货奖励',
		sellRewardList: '出货奖励',
		sellRewardDetail: '推手出货明细',
		//快递助手
		ExpressAssistant: '快递助手',
		commentDetail: '添加评价',
		//2.0.0版本
		LiveGroup: '直播间分组',
		GoodstockNo: '未入库商品',
		AlreadyCheckgood: '已入库商品',
		storedrule: '余额储值',
		storedPackage: '储值有礼',
		storedbalanceDetail: '余额明细',
		customerManagePage: '客户管理',
		customerMaintainPage: '客户沉淀',
		balanceCenterPage: '储值中心',
		storedPackageEdit: '编辑储值有礼',
		Codeconfig: '自动回复',
		payRecord: '充值记录',
		Contactcustomer: '联系顾问',
		goTocustomer: '进入客服系统',
		Teamperformance:'推手团队业绩',
		keyproline:'一键铺货',
		creatGoodDetailsAgain:'编辑商品',
		TostorePay:'到店支付数据',
		officialAccount: '微信公众号',		
		Assistindex:'助力领取',
		Editassistindex:'编辑助力领取',

		groupShare: '群分享',
		shareActivities: '群分享活动',
		editShareActivities: '编辑群分享活动',
		shareSet: '群分享设置',
		shareList: '群列表',
		AuditReply:'认证审核',

		messageManage: '信息管理',
		manageList: '内容营销',
		addMessage: '内容配置',
		groupList: '内容分组',

		batchAddFriend: '批量加好友',
		batchAddFriendRecord: '导入记录',
		qyWechatOperation: '企微SCRM',
		qyCustomerManage: '企微客户',
		tagLibrary:'标签库',
		dataOverView:'客户数据概览',
		clientBase:'客户群管理',
		clientGroupList:'客户群列表',
		clientGroupDetail:'客户群详情',
		GroupLiveCode:'客户群活码',
		changeLiveCode:'编辑群活码',
		storesLiveCode:'门店群活码',
		storeschangeLiveCode:'编辑群活码',
		groupPerformance:'客户群业绩',
		liveVideo:'视频号直播',
		liveCutIn:'直播接入',
		categorymanagement:'类目管理',
		shopwindow:'商品橱窗',
		friendsfission:'企微客户裂变',
		friendsfissionEdit:'配置好友裂变活动',
		friendsfissionData:'好友裂变活动数据',
		groupfission:'客户群裂变',
		groupfissionEdit:'配置群裂变活动',
		groupfissionData:'群裂变活动数据',
		callme:'员工活码',
		callmeedit:'配置员工活码',
		callmedata:'员工活码数据',
		risefans:'企微涨粉',
		risefansdetail:'商详页让利涨粉',
		risefanssuccess:'支付成功引导锁粉',
		myrecommended:'我的推荐奖励',
		myrecommendedinex:'我的推荐奖励',
		myrecommendedwithdrawal:'推荐奖励提现',
		Massrecord:'群发记录',
		MassrecordDetails:'群发详情',
		smartmarket:'智能营销',
		automarket:"自动营销",
		configuration:'配置自动营销',
		automarketdata:'自动营销数据',
		marketadvice:'营销建议'	,
		customerset:'客户授权',
		spellgroupbuy:'拼团抢购',
		spellgroupbuydetails:'配置拼团抢购',
		Newssubscription:'消息订阅',
		WxCustomerServe:'微信客服',
		serviceaccount:'客服帐号',
		editserviceaccount:'配置客服帐号',

		
		menumessage:'菜单消息',
		editmenumessage:'配置菜单消息',
		sceneaccess:'场景接入',
		servicetoolbar:'客服工具栏',
		
		smartreply:'智能回复',
		quickreply:'快捷回复',
		upgradeservice:'升级服务',
		receptiondata:'客服数据',
		
		cluesManage:'线索管理',
		clueSource:'线索来源',
		clueData: '线索池',
		sellClue: '销售线索',
		clueDataEdit: '线索信息配置',
		clueDataDetails: '线索详情',
		clueRecycleBin: '线索回收站',
		sendSampleList: '寄样管理',
		weixinAccountManage: '微信账号'
	},
	navbar: {
		logOut: '退出登录',
		dashboard: '首页',
		github: '项目地址',
		screenfull: '全屏',
		theme: '换肤',
		changePasswaord: '修改密码'
	},
	login: {
		title: '系统登录',
		logIn: '登录',
		username: '账号',
		password: '密码',
		any: '随便填',
		thirdparty: '第三方登录',
		thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！',
		remember: '记住密码',
	},
	documentation: {
		documentation: '文档',
		github: 'Github 地址'
	},
	permission: {
		roles: '你的权限',
		switchRoles: '切换权限'
	},
	components: {
		documentation: '文档',
		tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
		dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
		stickyTips: '当页面滚动到预设的位置会吸附在顶部',
		backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
		backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
		imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
	},
	table: {
		dynamicTips1: '固定表头, 按照表头顺序排序',
		dynamicTips2: '不固定表头, 按照点击顺序排序',
		dragTips1: '默认顺序',
		dragTips2: '拖拽后顺序',
		title: '标题',
		importance: '重要性',
		type: '类型',
		remark: '点评',
		search: '搜索',
		add: '添加',
		export: '导出',
		reviewer: '审核人',
		id: '序号',
		date: '时间',
		author: '作者',
		readings: '阅读数',
		status: '状态',
		actions: '操作',
		edit: '编辑',
		publish: '发布',
		draft: '草稿',
		delete: '删除',
		cancel: '取 消',
		confirm: '确 定'
	},
	errorLog: {
		tips: '请点击右上角bug小图标',
		description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
		documentation: '文档介绍'
	},
	excel: {
		export: '导出',
		selectedExport: '导出已选择项',
		placeholder: '请输入文件名(默认excel-list)'
	},
	zip: {
		export: '导出',
		placeholder: '请输入文件名(默认file)'
	},
	theme: {
		change: '换肤',
		documentation: '换肤文档',
		tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
	},
	tagsView: {
		close: '关闭',
		closeOthers: '关闭其它',
		closeAll: '关闭所有'
	}
}

